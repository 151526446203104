import { PAGE_READY_EVENT } from '@collective/utils/helpers';
import { useEffect } from 'react';

// Propagate an event to inform iframe the page loaded in order to achieve :
// https://linear.app/collective-work/issue/E-1252/[public-page-display]-show-an-error-when-we-fail-to-load-the-iframe
export const usePageReady = (collectiveId: string) => {
  useEffect(() => {
    window.parent.postMessage(
      {
        collectiveId,
        type: PAGE_READY_EVENT,
      },
      '*'
    );
  }, [collectiveId]);
};
